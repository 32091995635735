<template>
    <div>
        <CRow>
            <CCol md="12">
                <CCard>
                    <CCardHeader>
                        <CRow>
                            <CCol md="4">
                                Semua Tujuan Penggunaan Benih
                            </CCol>
                            <CCol md="8" class="text-right">
                                <router-link :to="{path: 'add-tujuan-benih'}" class="btn btn-success btn-sm"><i class="fa fa-plus"></i> Tambah</router-link>
                            </CCol>
                        </CRow>
                    </CCardHeader>
                    <CCardBody>
                        <CRow>
                            <CCol sm="12">
                                <CRow>
                                    <CCol sm="12">
                                        <div class="row">
											<div class="col-md-11">
												<div class="row row-condensed">
													<div class="as-col col-md">
														<div class="form-group">
															<b for="">Kategori Module</b>
															<v-select :options="list_kategori" v-model="kategori_selected" placeholder="Pilih Kategori Module"></v-select>
														</div>
													</div>
													<div class="as-col col-md">
														<div class="form-group">
															<b for="">Search</b>
															<input type="text" class="form-control" id="search-table" placeholder="Search.." v-model="searchData" />
														</div>
													</div>
												</div>
											</div>
											<div class="col-md-1">
												<br>
												<div class="btn btn-sm btn-primary" v-on:click="searchMaster"><i class="fa fa-filter"></i> Filter</div>
											</div>
										</div>
                                        <table class="table table-sm table-bordered">
                                            <thead class="bg-dark text-white">
                                                <tr>
                                                    <th>No</th>
                                                    <th>Kategori</th>
                                                    <th>Nama Tujuan Penggunaan Benih</th>
                                                    <th>Tipe Pemohon</th>
                                                    <th>Tipe Permohonan</th>
                                                    <th>Status</th>
                                                    <th>Aksi</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <template>
                                                    <tr v-if="loadingTable">
                                                        <td colspan="7"><i class="fa fa-spin fa-refresh"></i> Loading...</td>
                                                    </tr>
                                                    <tr v-for="(row_data, dokumen_persyaratan) in dataintable" :key="dokumen_persyaratan" v-else>
                                                        <td>
                                                            <template>
                                                                <div v-if="dokumen_persyaratan+1 == 10">
                                                                    {{curent_page}}0
                                                                </div>
                                                                <div v-else>
                                                                    
                                                                        {{(curent_page-1 != 0)?curent_page-1:''}}{{dokumen_persyaratan+1}}
                                                                </div>
                                                            </template>
                                                        </td>
                                                        <td>{{row_data.kategori_module}}</td>
                                                        <td>{{row_data.tpb_nama}}</td>
                                                        <td>
                                                            <div v-if="row_data.tipe_pemohon">

                                                                {{row_data.tipe_pemohon.tp_nama}}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div v-if="row_data.is_pemasukan">

                                                                Pemasukkan Benih Tanaman
                                                            </div>
                                                            <div v-else>

                                                                Pengeluaran Benih Tanaman
                                                            </div>
                                                        </td>
                                                        
                                                        <td>
                                                            <template>
                                                                <CBadge :color="getBadge(row_data.status)">
                                                                    <div v-if="row_data.status">
                                                                        Enable
                                                                    </div>
                                                                    <div v-else>
                                                                        Disable
                                                                    </div>
                                                                </CBadge>
                                                            </template>
                                                        </td>
                                                        <td>
                                                            <button class="btn btn-danger btn-sm" v-on:click="deleteData(row_data.id)"><i class="fa fa-trash"></i> Delete</button>
                                                            <router-link :to="{name: 'EditTujuanBenih', params: {idTujuanBenih: row_data.id}}" class="btn btn-info btn-sm"><i class="fa fa-pencil"></i> Edit</router-link>
                                                            <router-link :to="{name: 'SetDokumenPermohonan', params: {idTujuanBenih: row_data.id}}" class="btn btn-warning btn-sm"><i class="fa fa-cogs"></i> Set Dokumen Permohonan</router-link>
                                                        </td>
                                                    </tr>
                                                </template>
                                            </tbody> 
                                        </table>
                                        <nav aria-label="pagination">
                                            <ul class="pagination justify-content-start">
                                                <!---->
                                                <!-- {{ page.active ? 'active' : '' }} -->
                                                <li v-for="(page, key_page) in paging" :key="key_page" v-bind:class="{ 'active': page.active }" class="page-item">
                                                    <div v-on:click="toPage(page.url)" class="page-link c-page-link-number"> 
                                                        <div v-html="page.label"></div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </nav>
                                    </CCol>
                                </CRow>
                            </CCol>
                        </CRow>
                        <br />
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>
<script>
    const dataintable = [];
    export default {
        name: "AllTujuanBenih",
        components: {},
        data() {
            return {
                dataintable: [],
                paging: [],
                show_data_to: '',
                show_data_from: '',
                total_data: '',
                curent_page: '',
                loadingTable: true,
                list_kategori: '',
                kategori_selected: '',
                searchData: '',
            }
        },
        methods: {
            restoreData(id){
                // alert(id);
                Swal.fire({
                    title: "Apakah anda yakin?",
                    text: "Anda akan me-restore data tersebut.",
                    showDenyButton: false,
                    showCancelButton: true,
					confirmButtonClass: `btn btn-warning`,
                    confirmButtonText: `Proses`,
					cancelButtonClass: `btn btn-default`,
                    cancelButtonText: `Batal`,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        Swal.fire({
                            title: '<i class="fa fa-refresh fa-spin"></i>',
                            text: "Loading...",
                            showConfirmButton: false,
                        });
                        axios
                            .delete(this.apiLink + "api/crud/tujuan_penggunaan_benih", {
                                headers: {
                                    Authorization: "Bearer " + this.access_token,
                                },
                                data: {
                                    id: id,
                                },
                            })
                            .then((response) => {
                                var res_data = response.data;
                                // console.log(res_data);
                                if (res_data.meta.code != "200") {
                                    Swal.fire({
                                        icon: "error",
                                        title: "Oops...",
                                        text: res_data.data.message,
                                    });
                                } else {
                                    let timerInterval;
                                    Swal.fire({
                                        icon: "success",
                                        title: "Berhasil",
                                        text: "Data Kelompok Jenis Tanaman berhasil dikembalikan.",
                                        timer: 2000,
                                        timerProgressBar: true,
                                        showCancelButton: false,
                                        showConfirmButton: false,
                                        didOpen: () => {
                                            timerInterval = setInterval(() => {
                                                const content = Swal.getContent();
                                                if (content) {
                                                    const b = content.querySelector("b");
                                                    if (b) {
                                                        b.textContent = Swal.getTimerLeft();
                                                    }
                                                }
                                            }, 100);
                                        },
                                        willClose: () => {
                                            clearInterval(timerInterval);
                                        },
                                    }).then((result) => {
                                        /* Read more about handling dismissals below */
                                        if (result.dismiss === Swal.DismissReason.timer) {
                                            this.searchMaster();
                                        }
                                    });
                                }
                            });
                    } else if (result.isDenied) {
                        Swal.close();
                    }
                });
            },
            searchMaster(){
                this.loadingTable = true;
                axios
                .get(this.apiLink + "api/crud/tujuan_penggunaan_benih", {
                    params: {
                        search: this.searchData,
						kategori: this.kategori_selected,
                    },
                    headers: {
                        Authorization: "Bearer " + this.access_token,
                    },
                })
                .then((response) => {
					var dokumen_persyaratan = response.data;
                    var dokumen_persyaratan_data = dokumen_persyaratan.data[0];
                    // console.log(dokumen_persyaratan_data);
                    if (dokumen_persyaratan.meta.code != "200") {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: dokumen_persyaratan.data.message
                        });
                    }
                    else{
                        this.loadingTable = false;
                        this.dataintable = dokumen_persyaratan_data.data;
                        this.paging = dokumen_persyaratan_data.links;
                        this.show_data_from = dokumen_persyaratan_data.from;
                        this.show_data_to = dokumen_persyaratan_data.to;
                        this.curent_page = dokumen_persyaratan_data.current_page;
                    }
				});
            },
            toPage(url) {
                // alert(url);
                this.loadingTable = true;
                axios.get(url, {
                    headers: {
                        Authorization: "Bearer " + this.access_token,
                    },
                })
                .then((response) => {
                    var dokumen_persyaratan = response.data;
                    var dokumen_persyaratan_data = dokumen_persyaratan.data[0];
                    // console.log(dokumen_persyaratan_data);
                    if (dokumen_persyaratan.meta.code != "200") {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: dokumen_persyaratan.data.message
                        });
                    }
                    else{
                        this.loadingTable = false;
                        this.dataintable = dokumen_persyaratan_data.data;
                        this.paging = dokumen_persyaratan_data.links;
                        this.show_data_from = dokumen_persyaratan_data.from;
                        this.show_data_to = dokumen_persyaratan_data.to;
                        this.curent_page = dokumen_persyaratan_data.current_page;
                    }
                });
            },
            color(value) {
                let $color;
                if (value <= 25) {
                    $color = "info";
                } else if (value > 25 && value <= 50) {
                    $color = "success";
                } else if (value > 50 && value <= 75) {
                    $color = "warning";
                } else if (value > 75 && value <= 100) {
                    $color = "danger";
                }
                return $color;
            },
            getBadge(status) {
                if (status) {
                    return "success";
                }
                else{
                    return "danger";
                }
            },
            detailPermohonan(item) {
                this.$router.push("DetailPermohonan");
            },
            deleteData(id) {
                // alert(id);
                Swal.fire({
                    title: "Apakah anda yakin?",
                    text: "Anda tidak bisa mengembalikan data yang dihapus!",
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: `Hapus`,
                    denyButtonText: `Batal`,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        Swal.fire({
                            title: '<i class="fa fa-refresh fa-spin"></i>',
                            text: "Loading...",
                            showConfirmButton: false,
                        });
                        axios
                            .delete(this.apiLink + "api/crud/tujuan_penggunaan_benih", {
                                headers: {
                                    Authorization: "Bearer " + this.access_token,
                                },
                                data: {
                                    id: id,
                                },
                            })
                            .then((response) => {
                                var res_data = response.data;
                                // console.log(res_data);
                                if (res_data.meta.code != "200") {
                                    Swal.fire({
                                        icon: "error",
                                        title: "Oops...",
                                        text: res_data.data.message,
                                    });
                                } else {
                                    let timerInterval;
                                    Swal.fire({
                                        icon: "success",
                                        title: "Berhasil",
                                        text: "Data user berhasil dihapus.",
                                        timer: 2000,
                                        timerProgressBar: true,
                                        showCancelButton: false,
                                        showConfirmButton: false,
                                        didOpen: () => {
                                            timerInterval = setInterval(() => {
                                                const content = Swal.getContent();
                                                if (content) {
                                                    const b = content.querySelector("b");
                                                    if (b) {
                                                        b.textContent = Swal.getTimerLeft();
                                                    }
                                                }
                                            }, 100);
                                        },
                                        willClose: () => {
                                            clearInterval(timerInterval);
                                        },
                                    }).then((result) => {
                                        /* Read more about handling dismissals below */
                                        if (result.dismiss === Swal.DismissReason.timer) {
                                            window.location.reload();
                                        }
                                    });
                                }
                            });
                    } else if (result.isDenied) {
                        Swal.close();
                    }
                });
            },
        },
        beforeCreate() {
            this.loadingTable = true;
            axios.get(this.apiLink + "api/crud/tujuan_penggunaan_benih", {
                headers: {
                    Authorization: "Bearer " + this.access_token,
                },
            })
            .then((response) => {
                var dokumen_persyaratan = response.data;
                var dokumen_persyaratan_data = dokumen_persyaratan.data[0];
                // console.log(dokumen_persyaratan_data);
                if (dokumen_persyaratan.meta.code != "200") {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: dokumen_persyaratan.data.message
                    });
                }
                else{
                    this.loadingTable = false;
                    this.dataintable = dokumen_persyaratan_data.data;
                    this.paging = dokumen_persyaratan_data.links;
                    this.show_data_from = dokumen_persyaratan_data.from;
                    this.show_data_to = dokumen_persyaratan_data.to;
                    this.total_data = dokumen_persyaratan_data.total;
                    this.curent_page = dokumen_persyaratan_data.current_page;
                }
            });
            axios
                .get(this.apiLink + "api/master/category", {
                    headers: {
                        Authorization: "Bearer " + this.access_token,
                    },
                })
                .then((response) => {
                    var res_join_data_master = response.data;
                    // console.log();
                    if (res_join_data_master.meta.code != "200") {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: res_join_data_master.data.message,
                        });
                    } else {
                        this.list_kategori = res_join_data_master.data.master;
                    }
                });
        },
    };
</script>